import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header } from 'semantic-ui-react';
import { navigate } from "@reach/router";
import React from 'react';

import '../App.css';
import { EventLeaderboard, getLeaderboard } from '../services/NamerStats';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';
import { rarityClass, logEvent } from '../utils/utils';
import {Link} from '@reach/router';

export const About = (props:{
    id?: number;
    navigation: () => void;
}) => {

    React.useEffect(() => {
        props.navigation();
    }, [])

    return (<div>
        <div className='credits'>
            <br/>
            <h1>The Big Book of Behold Advice</h1>
            <br/>
            
            <p>
                <h2>Author</h2>
                <Link className={rarityClass(5)} to={"/staff/auto"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "auto")}>Automaton_2000</Link>
            </p>

            <p>
                <h2>Ratings and systems</h2>
                <Link className={rarityClass(4)} to={"/staff/namer"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "namer")}>NoNameNamer</Link>
            </p>

            <p>
                <h2>Contributors</h2>
                <Link className={rarityClass(5)} to={"/staff/idol"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "idol")}>Captain Idol</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/stars"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "stars")}>StarsAndGarters</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/big"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "big")}>BigMcLargeHuge</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/jenos"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "jenos")}>Jenos Idanian</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/nifty"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "nifty")}>Nifty Mittens</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/frank"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "frank")}>Frank</Link>
                <br/>
                <Link className={rarityClass(5)} to={"/staff/garak"} 
                    onClick={()=>logEvent("AuthorLink", "variantList", "garak")}>PlainSimpleGarak</Link>
            </p>
        </div>
        <br/>
        <Footer ads={false}/>
    </div>);
}

export default About;