import './assets/fomantic/dist/semantic.css';
import { Container, Header, Image, Menu, MenuItem, DropdownMenu, DropdownItem, Dropdown, DropdownDivider, Divider, Search, SearchResultProps, Rail, Sticky, Ref, Dimmer, Segment, Icon } from 'semantic-ui-react';
import ReactGA from 'react-ga4';
import React, { useEffect, useMemo } from 'react';
import { Router, RouteComponentProps, Link, navigate } from "@reach/router";
import './App.css';
import Voyagers from './components/Voyagers';
import Gauntleteers from './components/Gauntleteers';
import Featured from './components/Featured'
import { useStateWithStorage } from './utils/storage';
import Gauntlet from './components/Gauntlet';
import Fleets from './components/Fleets';
import Route from './Route';
import { isDesktop, isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Shuttles from './components/Shuttles';
import { getCrew, getGauntlet, NamerCrew } from './services/NamerStats';
import Events from './components/Events';
import Event from './components/Event';
import Collections from './components/Collections';
import CrewPage from './components/CrewPage';
import GoogleFontLoader from 'react-google-font-loader';
import Flavors from './components/Flavors';
import Overall from './components/Overall';
import Arena from './components/Arena';
import { logEvent, rarityClass, showThisCrew } from './utils/utils';
import debounce from 'lodash.debounce';
import Captains from './components/Captains';
import * as localForage from 'localforage';
import {Helmet} from 'react-helmet';
import About from './components/About';
import FAQ from './components/FAQ';
import Stats from './components/Stats';
import Help from './components/Help';
import NotFound from './components/NotFound';
import Ships from './components/Ships';
import CaptainPage from './components/CaptainPage';
import Snowfall from 'react-snowfall';
import FTM from './components/FTM';
import EventList from './components/EventList';
import FleetPage from './components/FleetPage';
import Quipments from './components/Quipments';
import PodcastList from './components/PodcastList';
import { Author } from './components/Author';

const SearchString = require('search-string');
const getAssetURL = (name?:string, full?:boolean, isWebp?:boolean) => {
  if (!name) {
    return "/images/fleet_battle_scenarios_practice_space_bg.png";
  }
  if (!isWebp) {
    name = name.replace(".webp", ".png");
  }
  if (process && process.env && process.env.NODE_ENV === 'development') {
    return "http://localhost:8788/images/" + name;
  }
  return (full?window.location.origin:"")+"/images/"+name;
}

function AppWrapper(props) {
  // const isOnlyMobile = isMobile && !isTablet;
  // if (!isOnlyMobile) {
    // const [showAd, setShowAd] = React.useState<boolean>(false);
    // React.useEffect(() => {
    //   localForage.getItem("nareksoji").then((localValue) => {
    //     if (!localValue) {
    //       setShowAd(true);
    //     }
    //   });
    // },[]);    
    // const hideAd = () => {
    //   setShowAd(false);
    //   localForage.setItem("nareksoji", true);
    // }

    // const getAd = (mobile?: boolean) => {
    //   let height = Math.min(600, window.innerHeight-40);
    //   if (mobile) {
    //     return <div>
    //       <big onClick={() => hideAd()}>[ remove ad ]</big>
    //       <br/>
    //       <a target="_blank" rel="noreferrer" href="https://forum.wickedrealmgames.com/stt">
    //         <img className="nareksoji-img" style={{maxHeight:height}} src='https://i.imgur.com/JwNBqxZ.png' onClick={()=>hideAd()}/>
    //       </a>
    //     </div>
    //   }
    //   return <div className="nareksoji">
    //       <small className="nareksoji-txt" onClick={() => hideAd()}>[ remove ad ]</small>
    //       <br/>
    //       <a target="_blank" rel="noreferrer" href="https://forum.wickedrealmgames.com/stt">
    //         <img className="nareksoji-img" style={{maxHeight:height}} src={assetURL+'nareksoji.webp'} onClick={()=>hideAd()}/>
    //       </a>
    //     </div>
    // }
    
    return (
      <React.Fragment>
        {/* {showAd && !isMobile && !isTablet && getAd()}
        {showAd && (isMobile || isTablet) && <Dimmer page active onClick={()=>hideAd()}>
          {getAd(true)}
        </Dimmer>} */}
        <Container>
          {props.children}
        </Container>
      </React.Fragment>
    )
  // } else {
  //   return (
  //     <div className="mobile-container">
  //       {props.children}
  //     </div>
  //   )
  // }
}

function App() {
  const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [ratingsSubmenu, setSubmenu] = React.useState<string>("");
  const [leaderboardSubmenu, setLeaderboardSubmenu] = React.useState<string>("");
  const [reactGAinitalized, setReactGAInitialized] = React.useState<boolean>(false);
  const [showSearch, setShowSearch] = React.useState<boolean>(true);
  const [searchLoading, setSearchLoading] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState([]);
  const [searchValues, setSearchValues] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [livestream, setLivestream] = React.useState<string|undefined>(undefined);
  const [christmas, setChristmas] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [timerID, setTimerID] = React.useState<NodeJS.Timeout|undefined>(undefined);
  const reloadInterval = 1800000; // 30 min

  if (!reactGAinitalized) {
    ReactGA.initialize("G-Y3LP27FJT9");
    setReactGAInitialized(true);
  }

  React.useEffect(() => {
    if (firstLoad == true && window.location.pathname != "/ratings/ships" && 
      window.location.pathname.startsWith("/ratings/")) {
      getCrew();
      setFirstLoad(false);
    }
    //getLivestreamLink().then(x=>setLivestream(x));
    
    let today = new Date();
    if ((today.getMonth() == 11 && today.getDate() >= 23) || (today.getMonth() == 0 && today.getDate() <= 1)) {
      setChristmas(true);
    }
  }, [firstLoad]);

  const startReloadTimer = () => {
    const id = setInterval(()=>setReload(true), reloadInterval);
    setTimerID(id);
  }

  React.useEffect(() => {
    startReloadTimer();
    return () => {
      debouncedChangeHandler.cancel();
    }
  }, []);

  React.useEffect(()=>{
    if (reload) {
      window.location.reload();
      //location.replace(location.pathname);
      // window.location = window.location;
      // window.self.window.self.window.window.location = window.location;
      setReload(false);
      startReloadTimer();
    }
  }, [reload])

  const isOnlyMobile = isMobile && !isTablet;

  const navigationComplete = (page:string, menuIndex:number, menuText:string, leaderMenuText:string, scroll:boolean, event:boolean, search:boolean, category?:string, action?:string) => {
    if (timerID) {
      clearInterval(timerID);
      startReloadTimer();
    }
    if (event) ReactGA.event({category, action});
    ReactGA.send({ hitType: "pageview", page: page });
    setShowSearch(search);
    setActiveIndex(menuIndex);
    setSubmenu(menuText);
    setLeaderboardSubmenu(leaderMenuText);
    if (scroll) window.scrollTo(0, 0);
  };

  // const handleResultSelect = (e, { result }) => { setSearchValue(""); setSearchResults([]); };

  const handleSearchChange = (e, { value }) => {
    const matches = (crew: NamerCrew, searchFilter: string) => {
      let filters = [];
      if (searchFilter) {
        let grouped = searchFilter.split(/\s+OR\s+/i);
        grouped.forEach(group => {
          filters.push(SearchString.parse(group));
        });
      }
      return showThisCrew(crew, filters, "Any match");
    }

    const mapResults = (crew: NamerCrew[]) => crew.sort((a,b)=>a.date>b.date?-1:1).map(c =>
    ({
        title: c.name,
        portrait: c.portrait,
        rarity: c.rarity,
        symbol: c.symbol
    }))

    setSearchLoading(true);

    setTimeout(() => {
      if (value.length < 3) {
        setSearchLoading(false);
        setSearchResults([]);
        return;
      }

      if (!searchValues || searchValues.length == 0) {
        getCrew().then(data => {
          if (!data) return;
          setSearchValues(data);
          setSearchLoading(false);
          let filteredData = data.filter(x=>matches(x, value));
          if (filteredData.length > 50) {
            filteredData = filteredData.slice(0, 49);
          }
          setSearchResults(mapResults(filteredData));
        });
      } else {
        setSearchLoading(false);
        let filteredData = searchValues.filter(x=>matches(x, value));
        if (filteredData.length > 50) {
          filteredData = filteredData.slice(0, 49);
        }
        setSearchResults(mapResults(filteredData));
      }
    }, 300)
  }

  const debouncedChangeHandler = useMemo(
    () => debounce(handleSearchChange, 300)
  , []);

  const searchResultRenderer = (crew: SearchResultProps) => 
    <Link className="searchResult" key={crew.symbol} onClick={()=>logEvent("CrewLink", "searchResult", crew.symbol)} to={"/crew/"+crew.symbol}>
        <img src={getAssetURL(crew.portrait)}/>
        <span className={rarityClass(crew.rarity)}>{crew.title}</span>
    </Link>

  const getTopMargin = () => {
    let top = 60 + (livestream?34:0);
    return top + "px";
  }

  return (
    <AppWrapper>
      <Helmet>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <title>The Big Book of Behold Advice</title>
        <link rel="canonical" href="https://www.bigbook.app" />
        <meta name="keywords" content="star,trek,timelines,stt,big,book,behold,advice,tier,tiers,datacore,card,cards,crew,power,ratings,scorecard,note,notes,review,reviews,evaluation,evaluations,voyage,gauntlet,arena,ship,battle,daily,shuttle,events,collections" />
        <meta name="application-name" content="The Big Book of Behold Advice"/>
        <meta name="description" content="The Big Book of Behold Advice 2.0 provides Star Trek Timelines crew data, evaluations and reviews. Crew scorecards contain utility ratings in areas of the game such as voyages, gauntlet, events, daily shuttles, ship battles and collections."/>
        <meta name="robots" content="index,follow"/>
        <meta name="googlebot" content="index,follow"/>
        <meta name="google" content="nositelinkssearchbox"/>
        <meta name="google" content="notranslate"/>
        <meta name="subject" content="Star Trek Timelines player resource"/>
        <meta name="rating" content="General"/>
        <meta name="referrer" content="no-referrer"/>
        <meta name="format-detection" content="telephone=no"/>
        <meta property="og:url" content="https://www.bigbook.app"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="The Big Book of Behold Advice"/>
        <meta property="og:image" content="https://www.bigbook.app/officiallogo.webp"/>
        <meta property="og:image:alt" content="Star Trek custom logo"/>
        <meta property="og:description" content="The Big Book of Behold Advice 2.0 provides Star Trek Timelines crew data, evaluations and reviews. Crew scorecards contain utility ratings in areas of the game such as voyages, gauntlet, events, daily shuttles, ship battles and collections."/>
        <meta property="og:site_name" content="The Big Book of Behold Advice"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="article:author" content="NoNameNamer"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:site" content="@Timelines_Talks"/>
        <meta name="twitter:creator" content="@Timelines_Talks"/>
        <meta name="twitter:url" content="https://www.bigbook.app"/>
        <meta name="twitter:title" content="The Big Book of Behold Advice"/>
        <meta name="twitter:description" content="The Big Book of Behold Advice 2.0 provides Star Trek Timelines crew data, evaluations and reviews. Crew scorecards contain utility ratings in areas of the game such as voyages, gauntlet, events, daily shuttles, ship battles and collections."/>
        <meta name="twitter:image" content="https://www.bigbook.app/officiallogo.webp"/>
        <meta name="twitter:image:alt" content="Star Trek custom logo"/>
      </Helmet>
      <GoogleFontLoader
          fonts={[
              {
              font: 'Roboto Slab',
              weights: [400],
              },
          ]}
          display="swap"
          subsets={['cyrillic-ext', 'greek']}
      />
      {christmas && <Snowfall
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
        }}
        snowflakeCount={isMobileOnly ? 10 : 20}
      />}
      <Container className="App-menu">
        <Menu tabular className={isOnlyMobile?"":""}>
          {isDesktop && <Image className="appLogo" src="/officiallogo.webp" onClick={() => navigate("/")}/>}
          {!isDesktop && <MenuItem className={activeIndex==0?`active`:`mmenu`} onClick={() => navigate("/")}>
            Featured
          </MenuItem>}
          <Dropdown item text={ratingsSubmenu&&ratingsSubmenu!=""?ratingsSubmenu:"Ratings"} className={activeIndex==1?`active`:`mmenu`}>
            <DropdownMenu>
              <DropdownItem onClick={() => navigate("/ratings/overall")}>Overall</DropdownItem>
              <DropdownDivider/>
              <DropdownItem onClick={() => navigate("/ratings/voyage")}>Voyage</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/gauntlet")}>Gauntlet</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/event")}>Event</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/collection")}>Collection</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/shuttle")}>Daily shuttle</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/arena")}>Ship battle</DropdownItem>
              <DropdownDivider/>
              <DropdownItem onClick={() => navigate("/ratings/quipments")}>Quipments</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/ships")}>Ships</DropdownItem>
              <DropdownItem onClick={() => navigate("/ratings/flavor")}>Flavor</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <MenuItem className={activeIndex==2?`active`:`mmenu`} onClick={() => navigate("/gauntlet")}>
            Gauntlet
          </MenuItem>
          {!isMobileOnly && <MenuItem className={activeIndex==3?`active`:`mmenu`} onClick={() => navigate("/events")}>
            Events
          </MenuItem>}
          
          {/* {!isOnlyMobile && <MenuItem className={activeIndex==4?`active`:``} onClick={() => navigate("/leaderboard")}>
            Leaderboard
          </MenuItem>}
          {isOnlyMobile && <Dropdown item text={"Misc"} className={activeIndex==4?`active`:``}>
            <DropdownMenu>
              <MenuItem onClick={() => navigate("/leaderboard")}>
                Leaderboard
              </MenuItem>
            </DropdownMenu>
          </Dropdown>} */}
          {/* {isOnlyMobile && <MenuItem className={activeIndex==3?`active`:``} onClick={() => navigate("/leaderboards/captain")}>
            Captains
          </MenuItem>} */}
          <Dropdown item text={leaderboardSubmenu&&leaderboardSubmenu!=""?leaderboardSubmenu:"Leaderboards"} className={activeIndex==4?`active`:`mmenu`}>
            <DropdownMenu>
              <DropdownItem onClick={() => navigate("/leaderboards/captain")}>Captains</DropdownItem>
              <DropdownItem onClick={() => navigate("/leaderboards/fleet")}>Fleets</DropdownItem>
              <DropdownItem onClick={() => navigate("/leaderboards/ftm")}>FTMs</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {!isMobileOnly && <MenuItem className={activeIndex==5?`active`:`mmenu`} onClick={() => navigate("/podcast")}>
            Podcast
          </MenuItem>}
          {showSearch && !isOnlyMobile && <Search size="small" aligned="right" values={searchValues} className="searchBar"
            loading={searchLoading}
            //onResultSelect={handleResultSelect}
            onSearchChange={(e,{value})=>{setSearchText(value); debouncedChangeHandler(e,{value})}}
            resultRenderer={searchResultRenderer}
            results={searchResults}
            value={searchText}
          />}
        </Menu>
      </Container>

      {livestream && <a target="_blank" className="livetext" href="https://twitch.tv/trektime" rel="noreferrer" onClick={() => {ReactGA.event({category:"ExternalLink",action:"livestream"})}}>
        <div className="notice" style={isMobileOnly?{top:"42.5px"}:{top:"53px"}}><div className="notice-inner">
        <span className="livestream">
          <Image className="twitchLogo" src={getAssetURL("twitch.webp")}/>
          {/* <span className="livetext"><b>TrekTime</b> is live streaming on Twitch!</span> */}
            <span><b>TrekTime</b> is live streaming on Twitch!</span>
          <div className="blob"/>
          {/* <Icon name="circle thin" size="small" color="red"/> */}
        </span>
      </div></div></a>}

      <div style={{paddingTop:getTopMargin()}}>
      <Router>
        <Route path="/" component={() => {
          return <Featured navigation={() => navigationComplete("/", 0, "", "", true, false, true)}/>}}
        />
        <Route path="/about" component={() => {
          return <About navigation={() => navigationComplete("/about", 10, "", "", true, false, true)}/>}}
        />
        <Route path="/faq" component={() => {
          return <FAQ navigation={() => navigationComplete("/faq", 10, "", "", true, false, true)}/>}}
        />
        <Route path="/help" component={() => {
          return <Help navigation={() => navigationComplete("/help", 10, "", "", true, false, true)}/>}}
        />
        <Route path="/stats" component={() => {
          return <Stats navigation={() => navigationComplete("/stats", 10, "", "", true, false, true)}/>}}
        />
        <Route path="/ratings/overall" component={() => {
          return <Overall navigation={() => navigationComplete("/ratings/overall", 1, "Overall", "", true, false, false)}/>}}
        />
        <Route path="/ratings/voyage" component={() => {
          return <Voyagers navigation={() => navigationComplete("/ratings/voyage", 1, "Voyage", "", true, false, false)}/>}}
        />
        <Route path="/ratings/gauntlet" component={() => {
          return <Gauntleteers navigation={() => navigationComplete("/ratings/gauntlet", 1, "Gauntlet", "", true, false, false)}/>}}
        />
        <Route path="/ratings/event" component={() => {
          return <Events navigation={() => navigationComplete("/ratings/event", 1, "Event", "", true, false, false)} />}}
        />
        <Route path="/ratings/collection" component={() => {
          return <Collections navigation={() => navigationComplete("/ratings/collection", 1, "Collection", "", true, false, false)} />}} 
        />
        <Route path="/ratings/arena" component={() => {
          return <Arena navigation={() => navigationComplete("/ratings/arena", 1, "Ship battle", "", true, false, false)} />}} 
        />
        <Route path="/ratings/shuttle" component={() => {
          return <Shuttles navigation={() => navigationComplete("/ratings/shuttle", 1, "Daily shuttle", "", true, false, false)}/>}} 
        />
        <Route path="/ratings/quipments" component={() => {
          return <Quipments navigation={() => navigationComplete("/ratings/quipments", 1, "Quipment", "", true, false, true)}/>}}
        />
        <Route path="/ratings/ships" component={() => {
          return <Ships navigation={() => navigationComplete("/ratings/ships", 1, "Ships", "", true, false, true)}/>}}
        />
        <Route path="/ratings/flavor" component={() => {
          return <Flavors navigation={() => navigationComplete("/ratings/flavor", 1, "Flavor", "", true, false, false)}/>}}
        />
        <Route path="/gauntlet" component={() => {
          return <Gauntlet navigation={() => navigationComplete("/gauntlet", 2, "", "", true, true, true, "gauntlet", "current")}/>}}
        />
        <Route<{ id: string }> path="/gauntlet/:raw" component={({ raw }) => {
          return <Gauntlet raw={raw!} navigation={() => navigationComplete("/gauntlet/raw", 2, "", "", true, true, true, "gauntlet", raw)} /> }}
        />
        <Route path="/events" component={() => {
          return <EventList navigation={() => navigationComplete("/events", 3, "", "", true, true, true)}/>}}
        />
        <Route path="/podcast" component={() => {
          return <PodcastList navigation={() => navigationComplete("/podcast", 5, "", "", true, true, true)}/>}}
        />
        <Route<{ id: string }> path="/event/:id" component={({ id }) => {
          return <Event id={id!} navigation={() => navigationComplete("/event/id", -1, "", "", true, true, true, "event", id)} /> }}
        />

        <Route path="/leaderboards/fleet" component={() => {
          return <Fleets navigation={() => navigationComplete("/leaderboards/fleet", 4, "", "Fleets", true, true, true, "leaderboard", "current")}/>}}
        />
        <Route<{ id: string }> path="/fleet/:id" component={({ id }) => {
          return <FleetPage fid={id!} navigation={() => navigationComplete("/fleet", -1, "", "", true, true, true, "fleet", id)}/> }}
        />

        <Route path="/leaderboards/captain" component={() => {
          return <Captains navigation={() => navigationComplete("/leaderboards/captain", 4, "", "Captains", true, true, true)}/>}}
        />
        <Route<{ pid: string }> path="/captain/:pid" component={({ pid }) => {
          return <CaptainPage pid={pid!} navigation={() => navigationComplete("/captain", -1, "", "", true, true, true, "captain", pid)}/> }}
        />

        <Route<{ symbol: string }> path="/crew/:symbol" component={({ symbol }) => {
          return <CrewPage symbol={symbol!} navigation={() => navigationComplete("/crew", -1, "", "", true, true, true, "crew", symbol)}/> }}
        />
        <Route<{ symbol: string }> path="/staff/:symbol" component={({ symbol }) => {
          return <Author symbol={symbol!} navigation={() => navigationComplete("/staff", -1, "", "", true, true, true, "staff", symbol)}/> }}
        />

        <Route path="/leaderboards/ftm" component={() => {
          return <FTM navigation={() => navigationComplete("/leaderboards/ftm", 4, "", "FTMs", true, true, true)}/>}} />
        <Route default component={() => {
            setShowSearch(true);
            setActiveIndex(-1);
            setSubmenu("");
            setLeaderboardSubmenu("");
            return <NotFound/>
          }}/>
      </Router>
      </div>
    </AppWrapper>
  );  
}

const RouterPage = (
  props: { pageComponent: JSX.Element } & RouteComponentProps
) => props.pageComponent; 

export default App;
export {getAssetURL as getAssetURL};