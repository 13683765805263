import '../assets/fomantic/dist/semantic.css';
import React from 'react';

import '../App.css';
import trimText from '../utils/trim';
import { isMobile, isTablet } from 'react-device-detect';

export const ReadMore = (props:{
    text: string
    author?: string
    date?: Date
    full?: boolean
}) => {
    const [primaryText, secondaryText] = props.full ? props.text : (isMobile && !isTablet ? trimText(props.text, 140, 160, 180) : trimText(props.text, 600, 700, 2500));
    const [short, setShort] = React.useState<boolean>(secondaryText != "" && !props.full);
    const [readMoreClicked, setReadMoreClicked] = React.useState<boolean>(false);

    let paragraphs = short ? primaryText.split("\n\n").slice(0, 2) : props.text.split("\n\n");
    if (!short && !readMoreClicked && paragraphs.length > 2 && !props.full) {
        paragraphs = paragraphs.slice(0, 2);
        setShort(true);
    }

    const addLineBreaks = (x) => {
        let tokens = x.split("\n");
        return tokens.map((s, idx) => <React.Fragment key={idx}>{s}{idx != tokens.length-1 ? <br/> : ""}</React.Fragment>)
    }

    if (short) {
        return <React.Fragment>
            {paragraphs.map((x, idx) =><p key={"p"+idx}>{addLineBreaks(x)}{idx < paragraphs.length-1 ? "" : <small className="readMore" onClick={() => {setReadMoreClicked(true); setShort(false)}}> [read more]</small>}</p>)}           
        </React.Fragment>
    }

    return <React.Fragment>
        {paragraphs.map((x, idx)=><p key={"p"+idx}>{addLineBreaks(x)}</p>)}
        {props.author && <div style={{textAlign:"right", fontSize: '85%'}}>-- by <i>{props.author}</i>{props.date?(" on "+new Date(new Date(props.date).getTime() + new Date(props.date).getTimezoneOffset() * 60000).toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})):""} </div>}
    </React.Fragment>
}

export default ReadMore;