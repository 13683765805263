import '../assets/fomantic/dist/semantic.css';
import { Accordion, Container, Grid, Icon, Segment, Label, LabelDetail, Loader, List } from 'semantic-ui-react';
import ReactGA from 'react-ga4';
import React from 'react';
import {Helmet} from 'react-helmet';
import {Link} from '@reach/router';

import '../App.css';
import { NamerCrew, getCrew, FeaturedCrewSkillInfo, CrewNote, getCrewNote, getQuipments, Quipment } from '../services/NamerStats';
import { FeaturedCrew, FeaturedCrewSkill, StatType } from './FeaturedCrew';
import VoyageStats from './CrewStats/VoyageStats';
import EventStats from './CrewStats/EventStats';
import GauntletStats from './CrewStats/GauntletStats';
import { Footer } from './Footer';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button";
import CollectionStats from './CrewStats/CollectionStats';
import ShuttleStats from './CrewStats/ShuttleStats';
import FlavorStats from './CrewStats/FlavorStats';
import ArenaStats from './CrewStats/ArenaStats';
import { logEvent, rarityClass } from '../utils/utils';
import { getAssetURL } from '../App';
import NotFound from './NotFound';
import { isMobileOnly, isMobile, isTablet, useMobileOrientation } from 'react-device-detect';
import Quipments from './Quipments';

export const Author = (props: {
    symbol: string;
    navigation: () => void;
}) => {
    
    React.useEffect(() => {
        props.navigation();
    }, [props.symbol]);
    
    let primary : FeaturedCrewSkillInfo = {
        skill: FeaturedCrewSkill.Command,
        base: 2000,
        min: 200,
        max: 600,
    };
    let rawPrimary : FeaturedCrewSkillInfo = {
        skill: FeaturedCrewSkill.Command,
        base: 1500,
        min: 1000,
        max: 500,
    };
    let secondary : FeaturedCrewSkillInfo = {
        skill: FeaturedCrewSkill.Diplomacy,
        base: 1500,
        min: 200,
        max: 400,
    };
    let rawSecondary : FeaturedCrewSkillInfo = {
        skill: FeaturedCrewSkill.Diplomacy,
        base: 1000,
        min: 150,
        max: 350,
    };
    let tertiary : FeaturedCrewSkillInfo = {
        skill: FeaturedCrewSkill.Security,
        base: 850,
        min: 130,
        max: 250,
    };
    let rawTertiary : FeaturedCrewSkillInfo = {
        skill: FeaturedCrewSkill.Security,
        base: 600,
        min: 100,
        max: 200
    };
    let crew: any = {
        flavor: "Flips hair frantically",
        fRank: 1,                
    };
    let note: CrewNote = {
        id: props.symbol,
        fTier: 1,
        fNote: "",
    };
    let mainNote: string = "";
    let name = "";
    let image = props.symbol + ".png";
    let portrait = props.symbol + "_portrait.png";
    let date = new Date();
    let traits = [];
    let obtained = "";
    let action = "";
    let link = "https://bigbook.app/staff/"+props.symbol;
    
    if (props.symbol == "stars") {
        primary.skill = FeaturedCrewSkill.Medicine;
        secondary.skill = FeaturedCrewSkill.Diplomacy;
        tertiary.skill = FeaturedCrewSkill.Science;
        name = "StarsAndGarters";
        obtained = "Mega";
        date = new Date('2018-10-08');
        action = "The Thing Is...";
        traits = ["Human", "Klingon", "Federation", "Starfleet", "Resourceful", "Writer", "Communicator", "Counselor", "Inspiring", "Duelist", "Prodigy", "Empath", "Merchant"];
        crew.flavor = "Flips hair frantically";
        note.fNote = "The fact of the matter is that Stars is the undisputed flavor champ of the Big Book. Endlessly memeable and totally distinct, he's even been irresistible to WRG staff who have made a handful of game changes at his behest. Lucky sod.";
        mainNote = `Destined to ramble like a '63 Ford, Stars emerged from the womb already the Excellence of Elocution. The first addition to the Big Book family post-release, he's a no-brainer auto-include on your roster as well.

    A Stubbsian wunderkind, Stars is the youngest Big Book crew that's been printed to date, but you'd never know it by his stats. Latching on to several projects at others' insistence, his encyclopedic knowledge is always in demand and gives him plus versatility in every phase of the game. A high point in Big Book art, too, thanks to the highest poly count among all crew, WRG had to invent higher levels of anti-aliasing and a brand new physics engine just to render his hair.

    Although his involvement in the Book has waxed and waned over the years, he still sits among the leaders for raw hours logged and buoys the endeavor whenever jumping in to do notes. More importantly, he's been the buoy in this author's personal life to keep him afloat through the worst of it. I have been and shall always be your friend.`;
    } else if (props.symbol == "garak") {
        primary.skill = FeaturedCrewSkill.Diplomacy;
        secondary.skill = FeaturedCrewSkill.Command;
        tertiary.skill = FeaturedCrewSkill.Engineering;
        name = "PlainSimpleGarak";
        obtained = "Pack";
        date = new Date('2018-11-14');
        action = "Got 'Em On The First Pull";
        traits = ["Caregiver", "Human", "Federation", "Civilian", "Resourceful", "Merchant", "Writer", "Communicator", "Inspiring", "Veteran", "Diplomat", "Hero", "Nurse", "Royalty"];
        crew.flavor = "'He was my father. I loved him.' - Feb 7, 2018";
        note.fNote = "Whether he only played our dad in Timelines or not, Pete really was all our dads. A low key flavor Hall of Famer, Garak's time constraints were the only limiter preventing him from being the face plastered on all that Timelines Talks and Big Book merch.";
        mainNote = `No, it's not a display error on the website. Oh, that one? Yea that's an error, let me ping Namer. What I meant was you really are seeing the first four skilled crew released in Timelines.

One of the level heads primed to offset the more sardonic among us, Garak earns his massive DIP proficiency, which blows longtime dice-rolling anchor Locutus out of the water. He doesn't make every gauntlet, but when you can pay the appearance fee, his one liners and references never miss. To pair with that, he boasts a nearly equal CMD roll. The man always walks softly because nobody questions who has the big stick in the room.

His father-centric exploits, shamefully buried this far down, earn him his ultra clutch third and fourth skills, as Garak barrels past Doc as the Renaissance Man that men want to be and women want their men to be. His humility has him dodging the headlines, but if you haven't pulled thirty packs to get him on your roster, you're just not playing the game right.`
} else if (props.symbol == "big") {
    primary.skill = FeaturedCrewSkill.Engineering;
    secondary.skill = FeaturedCrewSkill.Diplomacy;
    tertiary.skill = FeaturedCrewSkill.Security;
    name = "BigMcLargeHuge";
    obtained = "Pack";
    date = new Date('2019-04-09');
    action = "Poop Pee.";
    traits = ["Human", "Federation", "Starfleet", "Crafty", "Musician", "Veteran", "Jury Rigger", "Pilot", "Caregiver", "Gambler", "Klingon", "Writer", "Bartender", "Wadi"];
    crew.flavor = "A mammoth humanoid from the exotic southern island of Te'xas. Why does Big, the largest Big Booker, not simply eat the other Big Bookers?";
    note.fNote = "Clutch Friends reference aside, this flavor makes Big sound more like a lumbering oaf than the versatile lynchpin that he is. Still, we laud its multi-sentence structure and laugh unified at his towering height, at least until he gets hungry.";
    
    mainNote = `WRG is up to their old art size shenanigans again. Look out Age of Sail Worf, BigMcLargeHuge will crush you with ease. Of course, giant stature means a giant heart and Big, devoted father of four (Marissa, Jay Gordon, Patterson, some other one) and husband to super hottie wife, has the proven sensitivities to slot right into your Counselor seats on voyages despite lacking both that trait and the MED skill.

This card is an OG and so while power creep Canadians have attempted to fill his role of writing crew notes, Big was certainly a sight to see in days of yore. Massive proficiency minimums meant incredible consistency - every note he wrote was a home run and they detailed the intricacies of new crew while also being humorous and referential. No 25¢ copies of the Big Book get delivered to your doorstep without this man's stamp of approval.`
} else if (props.symbol == "idol") {
    primary.skill = FeaturedCrewSkill.Engineering;
    secondary.skill = FeaturedCrewSkill.Command;
    tertiary.skill = FeaturedCrewSkill.Diplomacy;
    name = "Captain Idol";
    obtained = "Event";
    date = new Date('2019-11-18');
    action = "Chop And Change";
    traits = ["Human", "Federation", "Starfleet", "Desperate", "Resourceful", "Communicator", "Cultural Figure", "Survivalist", "Explorer", "Ambassador", "Gambler", "Scoundrel", "Jury Rigger", "Maverick", "Chef", "Criminal", "Writer", "Bartender"];
    crew.flavor = "What better place to find oneself than on the computer in one's home basement?";
    note.fNote = "If the Idol card were sentient, he might quibble with CMD being only his secondary, but that merely speaks to his mastery of the art of jury rigging. A veteran of thousands of OBS and Streamdeck7474747 errors weekly, Idol is among the top engineers available in game and his talent in holography has benefitted the Big Book media team (he IS the Big Book media team). After dumping all his points in charisma, it's a wonder that he's as versatile as he is.";
    
    mainNote = `Captain Idol is a force of nature. Shoot first, ask question later is the credo for the man who not only absconded with Big Book branding for his own project, but was also later banned by WRG for unspeakable acts. It was the former which caught the eye of The Big Book administration and sparked their storied partnership.

A humble note farmer to begin his Timelines career, there wasn't much heraldry in writing about cadets and Idol, ever the showman, craved more. After joining the Big Book proper and puttering around with notes for a while, he quite literally took a gamble. With a webcam in one hand and his Big Book per diem in the other, he began streaming his mind boggling behold choices to the universe.

Now, years later, Idol bravely broadcasts his battle with addiction to the letter T through various themed shows and series hosted on a handful of flagging internet platforms. With many of his friends coming and going across his media empire, little does Captain Idol know that he lives out his life inside of a holodeck simulation that sits on somebody's desk.`;
} else if (props.symbol == "jenos") {
    primary.skill = FeaturedCrewSkill.Security;
    secondary.skill = FeaturedCrewSkill.Command;
    tertiary.skill = FeaturedCrewSkill.Science;
    name = "Jenos Idanian";
    obtained = "Event";
    date = new Date('2020-08-18');
    action = "*Arena Quote Intensifies*";
    traits = ["Human", "Federation", "Starfleet", "Brutal", "Duelist", "Scoundrel", "Writer", "Caregiver", "Maquis", "Historian"];
    crew.flavor = "He can come on a bit strong, but you'll get used to it. That's what she said.";
    note.fNote = "Stanley, you crush your wife during sex and your heart sucks. Boom, roasted. Andy, Cornell called. They think you suck...and you’re gayer than Oscar!";
    
    mainNote = `Ostensibly a troll, but also actually a troll, Jenos is like the Squire of Gothos. But if you need someone for an away mission at two in the morning, he'll always be there for you and isn't likely to accept a trip to the cryo vault.

His penchant for Fleet Boss Battles is only fitting - with the longest ship ability duration in the game, you can count on his attack boost even for exhaust plays against the doomsday machine. A reliable roster piece, Jenos is essentially the Jenos of Star Trek Timelines.`
} else if (props.symbol == "namer") {
    primary.skill = FeaturedCrewSkill.Security;
    secondary.skill = FeaturedCrewSkill.Engineering;
    tertiary.skill = FeaturedCrewSkill.Science;
    name = "NoNameNamer";
    obtained = "Giveaway";
    date = new Date('2020-09-24');
    action = "I Am Namer";
    image = "crew_full_body_cm_phlox_tribble_full.png";
    portrait = "crew_portraits_cm_phlox_tribble_sm.png";
    traits = ["Resourceful", "Tactician", "Crafty", "Survivalist", "Undercover Operative", "Innovator", "Saboteur", "Cyberneticist", "Hologram", "Prodigy", "Engineered", "Criminal", "Temporal Agent"];
    crew.flavor = "Unable to appear physically, the elusive humanoid figure attempts to manipulate affairs from Café Nervosa.";
    note.fNote = "He sleeps with a Varon-T disruptor under his pillow at night, and he sleeps very well knowing it's there too. Do you know why?";
    
    mainNote = `A shadowy flight into the dangerous world of a man who does not exist. NoName Namer, a young loner on a crusade to champion the cause of the innocent, the helpless, the casual. In a world of criminals who operate above the law…

Little is known about the man behind the curtain. Some circles believe he must be Romulan due to his extreme secrecy, while others are convinced his tricobalt name is a nod to his Pandronian lineage. However, none of that matters to WRG who continue to hunt the mastermind behind the Unsanctioned player tool which he maintains.

Even more devastating when directing his powers toward the game itself, Namer has won several events, during many of which updates continue to magically occur within the Big Book. 'Psionic resonator' this, 'Boreth clone' that - it remains unknown if Namer is using unnatural forces to place in events or if he's simply better than you.

NoNameNamer will be played by Ed Harris in the upcoming title, 'The Automaton Show'.`
} else if (props.symbol == "nifty") {
    primary.skill = FeaturedCrewSkill.Diplomacy;
    secondary.skill = FeaturedCrewSkill.Science;
    tertiary.skill = FeaturedCrewSkill.Medicine;
    name = "Nifty Mittens";
    obtained = "Event";
    date = new Date('2022-06-14');
    action = "Bruce, I'm God";
    traits = ["Human", "Federation", "Starfleet", "Crafty", "Inspiring", "Caregiver", "Hero", "Writer", "Athlete", "Prophet"];
    crew.flavor = "My grandfather was Canadian, you know. Well, they both get a lot of snow.";
    note.fNote = "Stereotypically Alaskan, Nifty is so nice that you can't even get mad at how much better he is than everybody else. Best paired with a blinding white backdrop.";
    
    mainNote = `Nifty was a WRG misprint - a card released too strong which, like the daily Quantum purchase, players were happy to stay quiet about. He's too handsome, too smart, and too good at too many things to be balanced. But in true M̴̳̋i̴̹̚s̴̱̍s̴̨̄i̸͕͗n̷̳͋g̶̳̊n̷̛͎ő̶͈.̵̈  fashion, there's another interesting quirk about this crew: a hidden anti-matter penalty on voyages.

Despite his powerful stats, there's a pre-requisite for use here - voyage revival tokens. "Niftying" a voyage is a common occurrence for the stupid, sexy Canadian which can waste hours of voyage progress if you're ill-prepared. Might he squander fewer voyages if he were released from his Big Book duties to focus on the game? We can't afford to find out.`
} else if (props.symbol == "frank") {
    primary.skill = FeaturedCrewSkill.Command;
    secondary = undefined;
    tertiary = undefined;
    name = "Frank";
    obtained = "Best Of pack";
    date = new Date('2023-02-13');
    action = "A Book? What Do You Want A Book For? To Read? Why Would You Want To Read When You Got The Television Set Sitting Right In Front Of You?";
    traits = ["Scoundrel", "Gambler", "Primal", "Writer", "Politician", "Smuggler", "Investigator", "Rich", "Veteran", "Communicator", "Historian"];
    crew.flavor = "Yada Yada Radoff. Yada Yada first to max.";
    note.fNote = "I noticed he's big on the phrase \"Yada yada\". Is Yada yada bad? Nah, Yada yada's good. He's very succinct.";
    
    mainNote = `Adding to WRG's slew of single skill crew who boast a big base, it's curious that they went with CMD rank #107 for Frank. A late addition to the Big Book, you may feel that we're already set on authors as you enjoy your daily read-through of crew notes perhaps to review my advice or even just read them for some friends. But you'd be remiss if you didn't add Frank's excellent referential work to your roster.

A veteran Timelines cetacean, Frank is also a spectacular writer. For instance, he was writing this screen play, a process which he finds absolutely exhilarating. It's an X-Men spin off in which he plays Professor Charles Xavier and, if you remember, he can control things with the power of his mind and make people do things and see things, so he thought 'what if you could do that for real?' Not in a comic book world, but in the real world. So in this film he plays a man who controls the world with his mind. For instance, he's walking along and he sees this beautiful girl and he thinks he'd like to see her naked and so all her clothes fall off and she's scrabbling around to get them back on again, but even before she can get her knickers on, he's seen everything, you know, he's seen it all.`
} else {
        return <NotFound/>
    }

    return <div>
        <Helmet>
            <title>{name}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={mainNote}/>
            <meta property="og:url" content={link}/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={name}/>
            <meta property="og:image" content={image}/>
            <meta property="og:image:alt" content="Crew portrait"/>
            <meta property="og:description" content={mainNote}/>
            <meta property="article:author" content="Automaton_2000"/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={name}/>
            <meta name="twitter:description" content={mainNote}/>
            <meta name="twitter:image" content={image}/>
            <meta name="twitter:image:alt" content="Crew portrait"/>
        </Helmet>
        <ScrollUpButton />
        <Grid stackable>
            <FeaturedCrew
                author={true}
                index={0}
                key={props.symbol}
                symbol={props.symbol}
                name={name}
                rarity={5}
                image={image}
                portrait={portrait}
                background={undefined}
                leftImage={true}
                primary={primary}
                secondary={secondary}
                tertiary={tertiary}
                rawPrimary={rawPrimary}
                rawSecondary={rawSecondary}
                rawTertiary={rawTertiary}
                note={mainNote}
                noteAuthor="Automaton_2000"
                noteDate={new Date('2023-07-24')}
                traits={traits}
                tier={1}
                event={10}
                eventR={1}
                voyage={10}
                voyageR={1}
                gauntlet={10}
                gauntletR={1}
                shuttle={10}
                shuttleR={1}
                coll={10}
                collR={1}
                arena={10}
                arenaR={1}
                crewPage={true}
                date={date}
                obtained={obtained}
                portal={true}
                showAd={false}
                handleClick={(stat) => {}}
            />
            <div className="crewStats">
                <Accordion inverted fluid exclusive={false}>
                    <Accordion.Title id={"statt"+StatType.Arena} active={true} index={StatType.Arena} onClick={() => {}}>
                        <h2><Icon name='dropdown' />Ship battle</h2>
                    </Accordion.Title>
                    <Accordion.Content id={"stat"+StatType.Arena} active={true}>
                        <Segment inverted className="statBg">
                        <List divided relaxed>
                            <List.Item style={{marginBottom:"5px"}}>
                                <Icon size="large" style={{marginRight:'10px'}}><img src={getAssetURL("ship_icon.png")} style={{maxWidth:"30px"}} alt="Action"/></Icon>
                                <List.Content>
                                    <List.Header style={{marginBottom:"5px"}}>{' '}<i><big>{action}</big></i></List.Header>
                                    <List.Description style={{marginBottom:"8px"}}>
                                    
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                        </Segment>
                    </Accordion.Content>
                    <Accordion.Title id={"statt"+StatType.Flavor} active={true} index={StatType.Flavor} onClick={() => {}}>
                        <h2><Icon name='dropdown' />Flavor</h2>
                    </Accordion.Title>
                    <Accordion.Content id={"stat"+StatType.Flavor} active={true}>
                        <Segment inverted className="statBg"><FlavorStats crew={crew} note={note} showAd={false}/></Segment>
                    </Accordion.Content>
                </Accordion>
            </div>
        </Grid>
        <Footer/>
    </div>;
}